import { observer } from "mobx-react-lite";
import { useState } from "react";
import EnquiryForm from "../enquiry/EnquiryForm";
import { useStore } from "../../app/stores/store";
import useEmblaCarousel from 'embla-carousel-react'

export default observer(function ResponsiveTrendyHomePage() {
    const [toggleBtn, setToggleBtn] = useState(true);
  const toggle = () => setToggleBtn(val => !val);
  const { commonStore,modalStore } = useStore();    
    const currentyear = new Date().getFullYear();
    const options = { align: 'start' }
  const [emblaRef, emblaApi] = useEmblaCarousel(options)
    const randomNumberInRange = (min, max) => { 
      return Math.floor(Math.random()  
              * (max - min + 1)) + min; 
  };
    return (
        <>
    {/* <section data-bs-version="5.1" className="menu menu1 algom5 cid-tbVVJqh8rW" id="amenu1-0">
    

    <nav className="navbar navbar-dropdown navbar-expand-lg">
        <div className="container container-head">
            
            <div className="icons-menu">
                <a className="iconfont-wrapper" href="https://www.facebook.com/profile.php?id=61554639157101" target="_blank">
                    <span className="p-2 mbr-iconfont socicon-facebook socicon"></span>
                </a>
                <a className="iconfont-wrapper" href="#" target="_blank">
                    <span className="p-2 mbr-iconfont socicon-twitter socicon"></span>
                </a>
                <a className="iconfont-wrapper" href="https://www.instagram.com/trendydashboardofficial/?igshid=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank">
                    <span className="p-2 mbr-iconfont socicon-instagram socicon"></span>
                </a>
                
            </div>
            
        </div>
        <div className="container">
            <div className="navbar-brand">
                <span className="navbar-logo">
                    <a href="#">
                        <img src="assets/images/trendydashboardlogo.webp" alt="Trendy Dashboard software company logo" style={{height: "4.9rem"}} />
                    </a>
                </span>
                <span className="navbar-caption-wrap"><a className="navbar-caption text-black display-7" href="#">Trendy Dashboard</a></span>
            </div>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <div className="hamburger">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true"><li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="#top">Home</a></li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="#trendydashboardjoinus">About Us</a>
                    </li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="#trendydashboardservices">Services</a>
                    </li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="#trendydashboardproducts">Product</a>
                    </li>
                    <li className="nav-item"><a className="nav-link link text-black text-primary display-4" href="#trendydashboardcontactus">Contact Us</a>
                    </li></ul>
            </div>
        </div>
    </nav>
</section> */}

<section data-bs-version="5.1" className="features4 algom5 cid-tXgLt5RcYL" id="trendydashboardjoinus">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="content-container">
                    <div className="col-12 col-lg-6 img-container">
                        <div className="image-wrapper">
                            <img src="assets/images/trendydashboardbanner.webp" alt="Trendy dashboard" />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 text-container">
                        <h1 className="mbr-section-title mbr-fonts-style display-2">Join Us on the Digital Journey</h1>
                        <p className="mbr-text mbr-fonts-style display-4">
                            As technology continues to reshape the business landscape, Trendy Dashboard is your trusted partner on the digital journey. We are committed to helping you navigate the complexities of the digital world and unlock new opportunities for growth.
                        </p>

                        <div className="col-list">
                            <p className="list-title mbr-fonts-style display-4">
                                Why Choose Us
                            </p>
                            <div className="list mbr-fonts-style">
                                <div className="item-wrap">
                                    <div className="iconfont-wrapper">
                                        <span className="mbr-iconfont mobi-mbri-success mobi-mbri"></span>
                                    </div>
                                    <p className="list-text mb-0 mbr-fonts-style display-7"><strong>
                                        Innovation</strong>: We are at the forefront of technology trends, constantly exploring new avenues to create innovative solutions that give you a competitive edge.
                                    </p>
                                </div>

                                <div className="item-wrap">
                                    <div className="iconfont-wrapper">
                                        <span className="mbr-iconfont mobi-mbri-success mobi-mbri"></span>
                                    </div>
                                    <p className="list-text mb-0 mbr-fonts-style display-7"><strong>
                                        Quality</strong>: We are dedicated to delivering top-notch software that is reliable, scalable, and efficient, ensuring your long-term success.
                                    </p>
                                </div>
                                <div className="item-wrap">
                                    <div className="iconfont-wrapper">
                                        <span className="mbr-iconfont mobi-mbri-success mobi-mbri"></span>
                                    </div>
                                    <p className="list-text mb-0 mbr-fonts-style display-7"><strong>
                                        Client-Centric</strong>: Our client's success is our success. We work closely with you to understand your goals and tailor our solutions to meet your specific requirements.<br/>
                                    </p>
                                </div>
                                <div className="item-wrap">
                                    <div className="iconfont-wrapper">
                                        <span className="mbr-iconfont mobi-mbri-success mobi-mbri"></span>
                                    </div>
                                    <p className="list-text mb-0 mbr-fonts-style display-7"><strong>
                                        Timely Delivery</strong>: We understand the importance of deadlines. Our agile development processes ensure that your projects are delivered on time and within budget.
                                    </p>
                                </div>
                                <div className="item-wrap">
                                    <div className="iconfont-wrapper">
                                        <span className="mbr-iconfont mobi-mbri-success mobi-mbri"></span>
                                    </div>
                                    <p className="list-text mb-0 mbr-fonts-style display-7"><strong>
                                        Long-Term Partnership</strong>: We believe in building lasting relationships with our clients. Our support and maintenance services ensure that your software remains up-to-date and relevant.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="mbr-section-btn"><a className="btn btn-black display-4" href="#trendydashboardenquiry">
                                <span className="mobi-mbri mobi-mbri-right mbr-iconfont mbr-iconfont-btn"></span>
                                Get Started Today</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" className="powerm5 list1 cid-tXhToloyPB" id="list1-q">

  

  
  
  <div className="container-fluid">

    <div className="row justify-content-between">
      <div className="col-12 col-lg-4 col-md-8 col-img">
        <div className="img-wrap">
          <div className="img-box">
            <img src="assets/images/software-developmentpic.webp" alt="Welcome to Trendy Dashboard, a pioneering force in the world of software innovation. As a dynamic and forward-thinking software company, we are dedicated to delivering cutting-edge solutions that empower businesses and individuals to thrive in the digital age." />
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-8 my-auto col-xl-7">
        <div className="mbr-section-head align-center">
          <h3 className="mbr-section-title mb-0 mbr-fonts-style display-2">Leading the Digital Revolution</h3>
          <h4 className="mbr-section-subtitle mb-0 mbr-fonts-style display-7">Welcome to Trendy Dashboard, a pioneering force in the world of software innovation. As a dynamic and forward-thinking software company, we are dedicated to delivering cutting-edge solutions that empower businesses and individuals to thrive in the digital age.</h4>
        </div>
        <div id="bootstrap-accordion_2" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
          <div className="card">
            <div className="card-header" role="tab" id="headingOne">
              <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse1_2" aria-expanded="false" aria-controls="collapse1">
                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-5">Our Mission</h6>
                <span className="sign mbr-iconfont mobi-mbri-plus"></span>
              </a>
            </div>
            <div id="collapse1_2" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_2">
              <div className="panel-body">
                <p className="mbr-fonts-style panel-text display-7">
                  Trendy Dashboard, mission is clear: to create software solutions that drive transformation and enable our clients to stay ahead of the curve. We believe that technology should be a catalyst for growth, and we are committed to making that vision a reality.
                </p>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" role="tab" id="headingOne">
              <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse2_2" aria-expanded="false" aria-controls="collapse2">
                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-5">Our Expertise</h6>
                <span className="sign mbr-iconfont mobi-mbri-plus"></span>
              </a>
            </div>
            <div id="collapse2_2" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_2">
              <div className="panel-body">
                <p className="mbr-fonts-style panel-text display-7"><strong>Trendy Dashboard</strong>, has a team of highly skilled and experienced professionals, we specialize in a wide range of software development services. Our expertise includes:
<br />
<br /><strong>Custom Software Development:</strong> We craft tailored software solutions that address your unique business needs. From enterprise-level applications to mobile apps, we have the expertise to bring your ideas to life.
<br /><strong>Web Development:</strong> Our web development team excels in creating responsive, user-friendly websites and web applications. We leverage the latest technologies to ensure your online presence stands out.
<br /><strong>Mobile App Development:</strong> In a mobile-first world, we design and develop mobile apps that provide exceptional user experiences. Whether it's iOS, Android, or cross-platform, we have you covered.
<br /><strong>AI and Machine Learning:</strong> We harness the power of artificial intelligence and machine learning to help you gain insights, automate processes, and make data-driven decisions.
<br /><strong>Cybersecurity:</strong> Protecting your digital assets is paramount. Our cybersecurity experts implement robust measures to safeguard your data and systems from threats.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section data-bs-version="5.1" className="features1 algom5 cid-tWsmwZadQ7" id="afeatures1-0">
    
    <div className="container">
        <div className="row">
            
        </div>
        <div className="row">
            <div className="col-12 cards-container">
                <div className="card">
                    <div className="card-box">
                        <div className="iconfont-wrapper">
                            <span className="mbr-iconfont mobi-mbri-idea mobi-mbri"></span>
                        </div>
                        <div className="card-container">
                            <h5 className="card-title align-center mbr-fonts-style mb-0 display-4">Build own Brand&nbsp;</h5>
                            <p className="card-text align-center mbr-fonts-style display-7">
                                Automate business process and allow customer to use business services.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-box">
                        <div className="iconfont-wrapper">
                            <span className="mbr-iconfont mobi-mbri-responsive-2 mobi-mbri"></span>
                        </div>
                        <div className="card-container">
                            <h5 className="card-title align-center mbr-fonts-style mb-0 display-4">Trendy Dashboard</h5>
                            <p className="card-text align-center mbr-fonts-style display-7">
                                Powerful trendy dashboard gives overall business insight
                            </p>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-box">
                        <div className="iconfont-wrapper">
                            <span className="mbr-iconfont mobi-mbri-user mobi-mbri"></span>
                        </div>
                        <div className="card-container">
                            <h5 className="card-title align-center mbr-fonts-style mb-0 display-4">Customize Mobile App&nbsp;</h5>
                            <p className="card-text align-center mbr-fonts-style display-7">
                                Build brand, lauch business apps with easier faster and more efficient manner.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" className="features20 cid-tX6b6a5yew" id="trendydashboardservices">
	
	<div className="container">
		<div className="row mb-5 justify-content-center">
			<div className="col-12 mb-0 content-head">
				<h3 className="mbr-section-title mbr-fonts-style align-center display-2">
					<strong>Our Services</strong>
				</h3>
				
			</div>
		</div>
		<div className="row">
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Basic Website Development</strong></h5>
						<p className="price mbr-fonts-style display-7">₹5,000 to ₹20,000 INR</p>
						<p className="card-text mbr-fonts-style display-7">
							A simple website with static content and a few pages
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Dynamic Website Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹20,000 to ₹50,000 INR
						</p>
						<p className="card-text mbr-fonts-style display-7">Features like content management systems (CMS) for easy content updates
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Custom Website Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹50,000 to ₹5,00,000 INR (or more, depending on complexity)
						</p>
						<p className="card-text mbr-fonts-style display-7">
							Tailored to specific business needs with custom features and design.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
			<div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Web Application Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹1,00,000 to ₹10,00,000 INR (or more, depending on complexity)
						</p>
						<p className="card-text mbr-fonts-style display-7">
							More complex websites with advanced functionality and interactivity.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div><div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Logo Design</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹500 to ₹2,000 INR
						</p>
						<p className="card-text mbr-fonts-style display-7">
							Managing and executing social media strategies to increase brand awareness, engagement, and reach the target audience.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div><div className="item features-without-image col-12 col-md-6 col-lg-6">
				<div className="item-wrapper">
					<div className="card-box align-left">
						
						<h5 className="card-title mbr-fonts-style display-4"><strong>Mobile App Development</strong></h5>
						<p className="price mbr-fonts-style display-7">
							₹50,000 to ₹20,00,000 INR
						</p>
						<p className="card-text mbr-fonts-style display-7">
							More complex mobile app with advanced functionality and interactivity.
						</p>
						<div className="mbr-section-btn mt-auto"><a className="btn btn-lg btn-primary display-7" href="#trendydashboardenquiry">Enquire Now</a></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section data-bs-version="5.1" className="slider2 cid-tYBE5momAu" id="trendydashboardproducts">   

    
    <div className="container-fluid">
        <div className="circle-blur circle-blur1"></div>
        <div className="circle-blur circle-blur2"></div>
        <div className="row justify-content-center">
            <div id="OurProducts" className="col-12 col-title">
                <h3 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">Our Products</h3>
                
            </div>
            <div className="col-12 col-carousel">
                <div className="carousel slide carousel-fade" id="tYUpWMbb3b" data-ride="carousel" data-bs-ride="carousel" data-interval="3000" data-bs-interval="3000">
                    <ol className="carousel-indicators">
                        <li data-slide-to="0" data-bs-slide-to="0" className="active" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li><li data-slide-to="1" data-bs-slide-to="1" className="" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li><li data-slide-to="2" data-bs-slide-to="2" className="" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li>
                        <li data-slide-to="3" data-bs-slide-to="3" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li>
                        <li data-slide-to="4" data-bs-slide-to="4" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li>
                        <li data-slide-to="5" data-bs-slide-to="5" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li>
                        <li data-slide-to="6" data-bs-slide-to="6" data-target="#tYUpWMbb3b" data-bs-target="#tYUpWMbb3b"></li>       
                        
                    </ol>
                    <div className="carousel-inner">
                        
                        <div className="carousel-item slider-image item active">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboardaccountingmanagement.webp" alt="Trendy dashbaord Accounting Financial management" data-slide-to="0" data-bs-slide-to="0" />
                                
                            </div>
                        </div>
                        <div className="carousel-item slider-image item">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboarddairymanagement.webp" alt="Trendy Dashboard Dairy management" data-slide-to="1" data-bs-slide-to="1" />
                                
                            </div>
                        </div><div className="carousel-item slider-image item">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboardhotelmanagement.webp" alt="Trendy dashboard hotel management" data-slide-to="2" data-bs-slide-to="2" />
                                
                            </div>
                        </div>
                        <div className="carousel-item slider-image item">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboardofficemanagement.webp" alt="Trendy dashboard Office management" data-slide-to="3" data-bs-slide-to="3" />
                                
                            </div>
                        </div>
                        <div className="carousel-item slider-image item">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboardpharmacymanagement.webp" alt="Trendy dashboard pharmacy management" data-slide-to="3" data-bs-slide-to="3" />
                                
                            </div>
                        </div>
                        <div className="carousel-item slider-image item">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboardrestaurantmanagement.webp" alt="Trendy dashboard Restaurant management" data-slide-to="3" data-bs-slide-to="3" />
                                
                            </div>
                        </div>
                        <div className="carousel-item slider-image item">
                            <div className="item-wrapper">
                                <img className="d-block w-100" src="assets/images/trendydashboardstoremanagement.webp" alt="Trendy dashboard store management" data-slide-to="3" data-bs-slide-to="3" />
                                
                            </div>
                        </div>
                    </div>
                    <a className="carousel-control carousel-control-prev" role="button" data-slide="prev" data-bs-slide="prev" href="#tYUpWMbb3b">
                        <span className="mobi-mbri mobi-mbri-arrow-prev" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden">Previous</span>
                    </a>
                    <a className="carousel-control carousel-control-next" role="button" data-slide="next" data-bs-slide="next" href="#tYUpWMbb3b">
                        <span className="mobi-mbri mobi-mbri-arrow-next" aria-hidden="true"></span>
                        <span className="sr-only visually-hidden">Next</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" className="pricing1 cid-tX69ZMgFDo" id="apricing1-f">

    <div className="align-center container">
        <div className="row">
            <div className="grid-container">
                <div className="col-12 main-col item features-image">
                    <div className="col-12 item-wrapper">
                        <div className="img-container">
                            <div className="img-wrap">
                                <img src="assets/images/istockphoto-hotelmanagementpic-612x344.webp" alt="Hotel Management" />
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="head-content">
                                <h1 className="mbr-section-title mbr-fonts-style align-left mbr-white mb-0 display-4"><strong>Hotel Management</strong></h1>
                                <div className="price-container">
                                    <p className="price-name mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        Starts at

                                    </p>
                                    <p className="price-number mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        ₹10,000 INR Yearly Per User</p>
                                </div>
                            </div>
                            <div className="foot-content">
                                <p className="mbr-text mbr-white align-left mb-0 mbr-fonts-style display-7">Trendy Dashboard offers complete solution for Hotel &amp; Hospitality management to handle hotel day to day operations. To book demo request<br /></p>
                                <div className="mbr-section-btn align-left"><a className="btn btn-black display-7" href="#trendydashboardenquiry">
                                        <div className="btn-transform">
                                            <div className="btn-transform-1">GET QUOTE</div>
                                        </div>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 main-col item features-image">
                    <div className="col-12 item-wrapper">
                        <div className="img-container">
                            <div className="img-wrap">
                                <img src="assets/images/restaurantmanagementpic-734x615.webp" alt="Restaurant Management" />
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="head-content">
                                <h1 className="mbr-section-title mbr-fonts-style align-left mbr-white mb-0 display-4"><strong>Restaurant Management</strong></h1>
                                <div className="price-container">
                                    <p className="price-name mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        Starts at</p>
                                    <p className="price-number mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        ₹10,000 INR Yearly Per User
                                    </p>
                                </div>
                            </div>
                            <div className="foot-content">
                                <p className="mbr-text mbr-white align-left mb-0 mbr-fonts-style display-7">
                                    Trendy Dashboard offers complete solution for Restaurant Management to handle restaurant day to day operations. To book demo get quote</p>
                                <div className="mbr-section-btn align-left"><a className="btn btn-black display-7" href="#trendydashboardenquiry">
                                        <div className="btn-transform">
                                            <div className="btn-transform-1">GET QUOTE</div>
                                        </div>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 main-col item features-image">
                    <div className="col-12 item-wrapper">
                        <div className="img-container">
                            <div className="img-wrap">
                                <img src="assets/images/store-management1200x630-cropped-734x385.webp" alt="Trendy Dasboard" />
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="head-content">
                                <h1 className="mbr-section-title mbr-fonts-style align-left mbr-white mb-0 display-4"><strong>Store Management</strong></h1>
                                <div className="price-container">
                                    <p className="price-name mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        Starts at

                                    </p>
                                    <p className="price-number mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        ₹10,000 INR Yearly Per User
                                    </p>
                                </div>
                            </div>
                            <div className="foot-content">
                                <p className="mbr-text mbr-white align-left mb-0 mbr-fonts-style display-7">
                                    Trendy Dashboard offers independent store management solution designed to streamline the operations of retail stores. To book demo request
                                </p>
                                <div className="mbr-section-btn align-left"><a className="btn btn-black display-7" href="#trendydashboardenquiry">
                                        <div className="btn-transform">
                                            <div className="btn-transform-1">GET QUOTE</div>
                                        </div>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section data-bs-version="5.1" className="pricing1 cid-tX6aY4xGdl" id="apricing1-g">

    <div className="align-center container">
        <div className="row">
            <div className="grid-container">
                <div className="col-12 main-col item features-image">
                    <div className="col-12 item-wrapper">
                        <div className="img-container">
                            <div className="img-wrap">
                                <img src="assets/images/officeimage-734x413.webp" alt="Office Management" />
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="head-content">
                                <h1 className="mbr-section-title mbr-fonts-style align-left mbr-white mb-0 display-4"><strong>Office Management</strong></h1>
                                <div className="price-container">
                                    <p className="price-name mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        Starts at

                                    </p>
                                    <p className="price-number mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        ₹10,000 INR Yearly Per User
                                    </p>
                                </div>
                            </div>
                            <div className="foot-content">
                                <p className="mbr-text mbr-white align-left mb-0 mbr-fonts-style display-7">
                                    Trendy Dashboard offers complete solution for Office management to handle day to day operations in easy way. To book demo request
                                </p>
                                <div className="mbr-section-btn align-left"><a className="btn btn-black display-7" href="#trendydashboardenquiry">
                                        <div className="btn-transform">
                                            <div className="btn-transform-1">GET QUOTE</div>
                                        </div>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 main-col item features-image">
                    <div className="col-12 item-wrapper">
                        <div className="img-container">
                            <div className="img-wrap">
                                <img src="assets/images/pharmacyimage-734x390.webp" alt="Pharmacy Management" />
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="head-content">
                                <h1 className="mbr-section-title mbr-fonts-style align-left mbr-white mb-0 display-4"><strong>Pharmacy Management</strong></h1>
                                <div className="price-container">
                                    <p className="price-name mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        Discovery Consultation
                                    </p>
                                    <p className="price-number mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        ₹10,000 INR Yearly Per User
                                    </p>
                                </div>
                            </div>
                            <div className="foot-content">
                                <p className="mbr-text mbr-white align-left mb-0 mbr-fonts-style display-7">
                                    Trendy Dashboard offers complete solution for Pharmacy Management to perform day to day operations. To book demo request
                                </p>
                                <div className="mbr-section-btn align-left"><a className="btn btn-black display-7" href="#trendydashboardenquiry">
                                        <div className="btn-transform">
                                            <div className="btn-transform-1">GET QUOTE</div>
                                        </div>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12 main-col item features-image">
                    <div className="col-12 item-wrapper">
                        <div className="img-container">
                            <div className="img-wrap">
                                <img src="assets/images/dairy-productimage-734x502.webp" alt="Dairy Management" />
                            </div>
                        </div>
                        <div className="title-container">
                            <div className="head-content">
                                <h1 className="mbr-section-title mbr-fonts-style align-left mbr-white mb-0 display-4"><strong>Dairy Management</strong></h1>
                                <div className="price-container">
                                    <p className="price-name mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        Starts at

                                    </p>
                                    <p className="price-number mbr-white align-left mb-0 mbr-fonts-style display-7">
                                        ₹10,000 INR Yearly Per User
                                    </p>
                                </div>
                            </div>
                            <div className="foot-content">
                                <p className="mbr-text mbr-white align-left mb-0 mbr-fonts-style display-7">
                                    Trendy Dashboard offers independent dairy management solution designed to streamline the operations of dairy. To book demo request
                                </p>
                                <div className="mbr-section-btn align-left"><a className="btn btn-black display-7" href="#trendydashboardenquiry">
                                        <div className="btn-transform">
                                            <div className="btn-transform-1">GET QUOTE</div>
                                        </div>
                                    </a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

{/* <section data-bs-version="5.1" className="clients1 cid-tWx6zJnfit" id="clients01-8">
    

    
    
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12">
                <h2 className="title mbr-fonts-style mb-5 display-1">
                    <strong>Our Clients</strong>
                </h2>
            </div>
            <div className="card col-12 col-md-3 col-lg-3" style={{marginBottom: '10px'}}>
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.traderssk.com/" target="_blank"><img src="assets/images/sk-traders-logo-280x280.webp" alt="S. K. Traders" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>
            <div className="card col-12 col-md-3 col-lg-3" style={{marginBottom: '10px'}}>
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.rajanenterprises.co.in/" target="_blank"><img src="assets/images/rajanenterpriseslogo-100x100.webp" alt="Rajan Enterprises" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>
            <div className="card col-12 col-md-3 col-lg-3" style={{marginBottom: '10px'}}>
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.ganpati-traders.in/" target="_blank"><img src="assets/images/ganpatitraderslogo-100x100.webp" alt="Ganpati Traders" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>
            <div className="card col-12 col-md-3 col-lg-3" style={{marginBottom: '10px'}}>
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.jmdenterprises.net.in/" target="_blank"><img src="assets/images/jmdenterpriseslogo-100x100.webp" alt="JMD Enterprises" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>
            <div className="card col-12 col-md-3 col-lg-3">
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.enterprisesss.info/" target="_blank"><img src="assets/images/ssenterpriseslogo-100x49.webp" alt="S. S. Enterprises" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>
            <div className="card col-12 col-md-3 col-lg-3">
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.bk-enterprises.co.in/" target="_blank"><img src="assets/images/bkenterpriseslogo-96x47.webp" alt="B. K. Enterprises" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>
            <div className="card col-12 col-md-3 col-lg-3">
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://www.ms-traders.in/" target="_blank"><img src="assets/images/tradersmslogo-100x49.webp" alt="MS Traders" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>   
            <div className="card col-12 col-md-3 col-lg-3">
                <div className="card-wrapper">
                    <div className="img-wrapper">
                        <a href="https://aestheicfashion.web.app/" target="_blank"><img src="assets/images/aesthetic-logo-1-100x100.webp" alt="MS Traders" /></a>
                    </div>
                    <div className="card-box align-center">
                        
                    </div>
                </div>
            </div>                  
            
        </div>
    </div>
</section> */}

<section data-bs-version="5.1" className="slider6 mbr-embla cid-uxYBMj4eqt" id="slider6-1w">
    
    <div className="position-relative">
        <div className="mbr-section-head">
            <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2"><strong>Our Client</strong></h4>
            <h5 className="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">Explore the latest client collections on Trendy dashboard</h5>
        </div>
        <div className="embla mt-4" data-skip-snaps="true" data-align="center" data-contain-scroll="trimSnaps" data-loop="true" data-auto-play="true" data-auto-play-interval="4" data-draggable="true">
            <div className="embla__viewport container" ref={emblaRef}>
                <div className="embla__container">
                <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/ssenterpriseslogo-100x49.webp" style={{width:'100px'}} alt="ss enterprises" title="ss enterprises" />
                                </div>
                            </div>
                            <div className="item-content">E-commerce category for fashion clothing products                                
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.enterprisesss.info/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/bkenterpriseslogo-96x47.webp" style={{width:'100px'}} alt="B. K. Enterprises" title="B. K. Enterprises" />
                                </div>
                            </div>
                            <div className="item-content">E-commerce category for fashion clothing products                                
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.bk-enterprises.co.in/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/tradersmslogo-100x49.webp" style={{width:'100px'}} alt="MS Traders" title="MS Traders" />
                                </div>
                            </div>
                            <div className="item-content">E-commerce category for fashion clothing products                                
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.ms-traders.in/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/aesthetic-logo-1-100x100.webp" style={{width:'100px'}} alt="Aestheic fashion" title="Aestheic fashion" />
                                </div>
                            </div>
                            <div className="item-content">E-commerce category for fashion clothing products                                
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://aestheicfashion.web.app/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/sk-traders-logo-280x280.webp" style={{width:'100px'}} alt="sk-traders" title="sk-traders" />
                                </div>
                            </div>
                            <div className="item-content">
                            Wholesale category for organic agricultural products
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.traderssk.com/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/rajanenterpriseslogo-100x100.webp" style={{width:'100px'}} alt="rajan enterprises" title="rajan enterprises" />
                                </div>
                            </div>
                            <div className="item-content">Wholesale category for organic agricultural products    
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.rajanenterprises.co.in/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/ganpatitraderslogo-100x100.webp" style={{width:'100px'}} alt="ganpati traders" title="ganpati traders" />
                                </div>
                            </div>
                            <div className="item-content">Wholesale category for organic agricultural products  
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.ganpati-traders.in/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    <div className="embla__slide slider-image item" style={{marginLeft: "1rem", marginRight: "1rem"}}>
                        <div className="slide-content">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/jmdenterpriseslogo-100x100.webp" style={{width:'100px'}} alt="jmd enterprises" title="jmd enterprises" />
                                </div>
                            </div>
                            <div className="item-content">Wholesale category for organic agricultural products                                
                            </div>
                            <div className="mbr-section-btn item-footer mt-2"><a href="https://www.jmdenterprises.net.in/" className="btn item-btn btn-primary-outline display-7" target="_blank">Read More &gt;</a></div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <button className="embla__button embla__button--prev" onClick={()=>{
                        if (!emblaApi) return;
                        emblaApi.scrollPrev();
                    }}>
                <span className="mobi-mbri mobi-mbri-arrow-prev mbr-iconfont" aria-hidden="true"></span>
                <span className="sr-only visually-hidden visually-hidden">Previous</span>
            </button>
            <button className="embla__button embla__button--next" onClick={()=>{
                        if (!emblaApi) return;
                        emblaApi.scrollNext();
                    }}>
                <span className="mobi-mbri mobi-mbri-arrow-next mbr-iconfont" aria-hidden="true"></span>
                <span className="sr-only visually-hidden visually-hidden">Next</span>
            </button>
        </div>
    </div> 
</section>

<section data-bs-version="5.1" className="map1 deepm5 cid-tWxeSA6xpb" id="trendydashboardcontactus">

    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-5">
                <div className="map-wrapper">
                    <div className="google-map"><iframe style={{border:"0"}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d868.4081474536267!2d77.7145708!3d29.4687372!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390c1b506d438de3%3A0x8c25e91e1d486f2e!2sTrendy%20Dashboard!5e0!3m2!1sen!2sin!4v1692120816784!5m2!1sen!2sin" allowFullScreen={true}></iframe></div>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="title-wrapper">
                    <h3 className="mbr-section-title mbr-fonts-style display-5">
                        <strong>Contact</strong></h3>
                </div>
                <div className="address-wrapper">
                    <div className="text-wrapper">
                        <span className="mbr-iconfont mobi-mbri-map-pin mobi-mbri"></span>
                        <div className="text-wrap">
                            <h4 className="mbr-section-subtitle mbr-fonts-style display-7">
                                <strong>Location</strong>
                            </h4>
                            <p className="mbr-text mbr-fonts-style display-4">
                                Trendy Dashboard, Sector 46, Noida, Uttar Pradesh</p>
                        </div>
                    </div>
                    <div className="text-wrapper">
                        <span className="mbr-iconfont mobi-mbri-map-pin mobi-mbri"></span>
                        <div className="text-wrap">
                            <h4 className="mbr-section-subtitle mbr-fonts-style display-7">
                                <strong>Location</strong>
                            </h4>
                            <p className="mbr-text mbr-fonts-style display-4">
                                Trendy Dashboard, Patel Nagar, Muzaffarnagar, Uttar Pradesh</p>
                        </div>
                    </div>
                </div>
                <div className="links-wrapper">
                    <div className="links-wrap">
                        <a href="#">
                            <span className="mbr-iconfont mobi-mbri-letter mobi-mbri"></span>
                            <p className="mbr-email mbr-fonts-style display-7">
                                <strong>support@trendydashboard.com</strong>
                            </p>
                        </a>
                    </div>
                    <div className="links-wrap">
                        <a href="https://wa.me/919910602991">
                            <span className="mbr-iconfont mobi-mbri-phone mobi-mbri"></span>
                            <p className="mbr-phone mbr-fonts-style display-7"><strong>919910602991</strong></p>
                            </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section data-bs-version="5.1" className="form01 trackm5 cid-tYRtBpJGI5" id="trendydashboardenquiry">
    

    
    

    <div className="container-fluid">
        <div className="row">
            <div className="col-12 col-lg-6 card">
                <div className="content-wrapper">
                    <div className="content-wrap">
                        <h2 className="mbr-section-title mbr-fonts-style display-2">
                            Which service ace is <span>interested in?</span>
                        </h2>
                        <p className="mbr-text mbr-fonts-style display-5">
                            <span>You will receive an answer</span> <br /> to your application within <br /> two hours
                        </p>
                        <div className="person-wrapper">
                            <div className="person-wrap">
                                <img src="assets/images/image2.webp" alt="" />
                                <div className="name-wrap">
                                    <p className="mbr-name mbr-fonts-style display-5">
                                        <strong>Neha</strong>
                                    </p>
                                    <p className="mbr-role mbr-fonts-style display-7">
                                        Client manager
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-lg-6 card">
                <div className="mbr-form form-wrapper" data-form-type="formoid">
                    <EnquiryForm />
                </div>
            </div>
        </div>
    </div>
</section>
        </>
    )
})